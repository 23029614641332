const map = {
  "//127.0.0.1:7200/": "//sanfangtong.sointech.com.cn/demo-vue2/",
  "//127.0.0.1:7300/": "//sanfangtong.sointech.com.cn/child_project02/",
  "//127.0.0.1:7400/": "//sanfangtong.sointech.com.cn/child_demo/",
  "//127.0.0.1:8088/": "//sanfangtong.sointech.com.cn/main-vue/",
  // "//127.0.0.1:80/": "//127.0.0.1:80/",
};

export default function hostMap(host) {
  if (process.env.NODE_ENV === "production") return map[host];
  return host;
}
